import {apiClient} from './apiClient';


//API call to register user
export const registerUser = async(username,email,password) => {
    try{
        const response = await apiClient.post('/register',{username,email,password});
        return response.data;
    }
    catch(error){
        throw error;
    }
}


//API call to send OTP
export const sendOTP = async(username,email,phone) => {
    try{
        const response = await apiClient.post('/sendOTP',{username,email,phone});
        return response.data;
    }
    catch(error){
        throw error;
    }
}


// API call to validate OTP
export const validateOTP = async(email,OTP) => {
    try{
        const response = await apiClient.post('/validateOTP',{email,OTP});
        return response.data;
    }
    catch(error){
        throw error;
    }
}

//API call for user login 
export const loginUser = async(email,password) => {
    try{
        const response = await apiClient.post('/login',{email,password});
        const {access_token,username} = response.data;
        localStorage.setItem('token',access_token);
        localStorage.setItem('username',username);
        return response.data;
    }
    catch(error){
        throw error;
    }
}

//API call for booking
export const createBooking = async(bookingData) => {
    console.log(bookingData,"in apicalls")
    try{
        const response = await apiClient.post('/create_booking',bookingData);
        return response.data;
    }
    catch(error){
        throw error;
    }
}

// API call for blocked dates
export const blockedDatesList = async(location) => {
    try{
        const response = await apiClient.get(`/blocked_dates/${location}`);
        return response.data;
    }
    catch(error){
        throw error;
    }
}

// API call to delete blocked dates 
export const deleteBlockedDates = async() => {
    try{
        await apiClient.post('/delete_blocked_dates');
    }
    catch(error){
        console.log(error);
    }
}