// Navbar.js
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import "../styles/Navbar.css";
import image2 from "../images/navbar_logo.png";
import image from "../images/AeroQuest_2.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [username, setUsername] = useState("");
  const dropdownRef = useRef(null);
  // useEffect(() => {
  //   setusername(localStorage.getItem('username'))
  // }, [localStorage.getItem('username')])

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    setUsername(storedUsername);

    // Event listener for custom storage event
    const handleStorageChange = (event) => {
      if (event.key === "username") {
        setUsername(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);

  const toggleLoginDropdown = () => {
    setIsLoginDropdownOpen(!isLoginDropdownOpen);
  };
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    navigate("/login");
  };
  const handleBookingDetails = () => {};
  const handleAccountDetails = () => {};
  useEffect(() => {
    // Add event listener for clicks outside of dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    // Check if the clicked element is not the dropdown or its child
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsLoginDropdownOpen(false); // Close dropdown when clicking outside
    }
  };
  return (
    <nav className="navbar">
      <div className="navbar-title" onClick={() => navigate('/home')}>
              <img src={image2} alt="logo" className="navbar_logo_image" />
              {/* <img src={image} alt="logo_text" className="logo_image" /> */}
      </div>
      <div className={`navbar-links ${isMenuOpen ? "active" : ""}`}>
        <ul>
          <li>
            <a href="/home">
              <i className="fa-solid fa-house"></i> Home
            </a>
          </li>
          <li>
            <a href="/summary">
              <i className="fa-solid fa-cart-shopping"></i> Cart
            </a>
          </li>
          <li>
            <a href="/">
              <i className="fa-solid fa-hands-clapping"></i> About
            </a>
          </li>
        </ul>
        <div className="navbar-user-info">
          {username ? (
            <>
              <div
                className="navbar-user-profile"
                onClick={toggleLoginDropdown}
              >
                <div className="navbar-user-profile-icon">
                  <i className="fa-regular fa-circle-user"></i>
                </div>
                <div className="navbar-user-profile-name">{username}</div>
              </div>
              {isLoginDropdownOpen && (
                <div className="navbar-login-dropdown-menu" ref={dropdownRef}>
                  {/* <div
                    className="navbar-login-dropdown-item"
                    onClick={handleAccountDetails}
                  >
                    <i className="fa-solid fa-gear navbar-login-dropdown-icons"></i>
                    Settings
                  </div>
                  <div
                    className="navbar-login-dropdown-item"
                    onClick={handleBookingDetails}
                  >
                    <i className="fa-solid fa-file-invoice navbar-login-dropdown-icons"></i>
                    Bookings
                  </div> */}
                  <div
                    className="navbar-login-dropdown-item navbar-login-dropdown-item-logout"
                    onClick={handleLogout}
                  >
                    <i className="fa-solid fa-right-from-bracket navbar-login-dropdown-icons"></i>
                    Logout
                  </div>
                </div>
              )}
            </>
          ) : (
            <button className="navbar-login-button" onClick={handleLogin}>
              {" "}
              Login
            </button>
          )}
        </div>
      </div>
      <button className="hamburger" onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>
    </nav>
  );
};

export default Navbar;
