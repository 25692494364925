import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";

import Popup from "../components/PopupComponent";
import { registerUser, sendOTP, validateOTP } from "../apis/apiCalls";
import "../styles/RegistrationPage.css";
import logo from "../images/Logo_4.png";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [phone, setPhone] = useState("");
  const [OTP, setOTP] = useState();
  const [sendingOTP, setsendingOTP] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailVerified) {
      setError("Please Verify your email before proceeding further");
      setTimeout(() => {
        setError("");
      }, 5000);
    } else {
      setError("");
      setSuccess("");

      try {
        const result = await registerUser(username, email, password);
        setSuccess(result.message);
        navigate("/login");
      } catch (err) {
        if (err.response.data.message) setError(err.response.data.message);
        else setError("An error occurred during registration");
        console.log(err);
      }
    }
  };
  const handleLoginRedirect = () => {
    navigate("/login");
  };
  const handleVerifyPhoneNumber = async () => {
    try {
      setsendingOTP(true);
      const result = await sendOTP(username, email, phone);
      setsendingOTP(false);
      console.log("result", result);
      setIsPopupOpen(true);
    } catch (err) {
      // if(err.response.data.message) setError(err.response.data.message);
      // else
      setError("An error occurred while sending OTP");
      setsendingOTP(false);
      console.log(err);
    }
  };
  const validateOTPFunc = async () => {
    try {
      const result = await validateOTP(email, OTP);
      console.log(result);
      setEmailVerified(true);
      setIsPopupOpen(false);
      setWrongOTP(false);
    } catch (error) {
      setWrongOTP(true);
    }
  };

  return (
    <div className="registration-block">
      <div className="registration-container">
        {/* <h1 className="registration-app-title">
            Sky Scheduler
            <i className="fas fa-plane  registration-logo"></i>
            
        </h1> */}
        <div className=" registration-app-title">
          <img src={logo} alt="logo" className="banner_logo_image" />
          {/* <img src={image} alt="logo_text" className="banner_logo_image" /> */}
        </div>
        <div className="registration_form_block">
          <div className="registration-title">Create an Account</div>

          <form className="registration-form" onSubmit={handleSubmit}>
            <div className="registration-input-group">
              <TextField
                label="Username"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                required
                fullWidth
              />
            </div>
            <div className="registration-input-group">
              <TextField
                label="Email Address"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailVerified(false);
                }}
                type="email"
                required
                fullWidth
                InputProps={{
                  endAdornment: emailVerified ? (
                    <>
                      <i className="fa-solid fa-circle-check verified_email_icon"></i>
                    </>
                  ) : sendingOTP ? (
                    <>
                      <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                      Verifying
                    </>
                  ) : (
                    <Button onClick={handleVerifyPhoneNumber} type="button">
                      Verify
                    </Button>
                  ),
                }}
              />
            </div>
            {/* <div className="registration-input-group">
              <TextField
                label="Phone Number"
                variant="outlined" 
                value={phone}
                inputProps={{
                  pattern: "\\d{10}", // Restrict phone number format
                  maxLength: 10, // Limit the input to 14 characters for format like (xxx) xxx-xxxx
                }}
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Button onClick={handleVerifyPhoneNumber} type="button">Verify</Button>
                  )
                }}
              />
            </div> */}
            <div className="registration-input-group">
              <TextField
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={handleTogglePasswordVisibility}
                      type="button"
                    >
                      <i
                        className={`fas ${
                          showPassword ? "fa-eye-slash" : "fa-eye"
                        }`}
                      ></i>
                    </Button>
                  ),
                }}
              />
            </div>
            <button type="submit" className="registration-button">
              Register
            </button>
            {error && <p className="registration-error">{error}</p>}
            {success && <p className="registration-success">{success}</p>}
            <div className="login-message">
              Already have an account? Please
              <button
                type="button"
                className="login-redirect-button"
                onClick={handleLoginRedirect}
              >
                Login
              </button>
            </div>
          </form>
        </div>

        {isPopupOpen && (
          <Popup>
            <div className="otp-popup-header-content">
              <div className="otp-popup-header-content-title">
                <h3>OTP Verification</h3>
              </div>
              <button className="otp-popup-close-btn" onClick={closePopup}>
                &times;
              </button>
            </div>
            <div className="otp-popup-body">
              <p className="otp-popup-body-information">
                We have sent OTP on "{email}"
              </p>
              {/* "{phone}" and  */}
              <div>
                {/* Mobile OTP */}
                <TextField
                  label="OTP"
                  variant="outlined"
                  type="tel"
                  inputProps={{
                    pattern: "d{6}",
                    maxLength: 6,
                  }}
                  fullWidth
                  required
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                />
                {wrongOTP && (
                  <p className="registration-error">Please enter valid OTP</p>
                )}
                <p>
                  Don't receive the OTP?{" "}
                  <button
                    className="resend_otp_button"
                    onClick={handleVerifyPhoneNumber}
                  >
                    Resend OTP
                  </button>
                </p>
              </div>
            </div>
            <div className="otp-popup-footer">
              <button
                className="otp-popup-action-btn"
                onClick={validateOTPFunc}
              >
                Valdidate OTP
              </button>
            </div>
          </Popup>
        )}
      </div>
    </div>
  );
};

export default Register;
