import React from 'react';

import '../styles/PopupComponent.css'; 

const Popup = ({ headerText, children, onClose }) => {
    console.log("came into poopup")
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {children}
      </div>
    </div>
  );
};

export default Popup;
