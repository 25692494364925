import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

import { loginUser } from "../apis/apiCalls";
import logo from "../images/Logo_4.png";
import "../styles/LoginPage.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  localStorage.clear();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      const result = await loginUser(email, password);
      setSuccess(result.message);
      navigate("/home");
    } catch (err) {
      if (err.response.data.message) setError(err.response.data.message);
      else setError("An error occurred during Login");
      console.log(err);
    }
  };
  const handleRegisterRedirect = () => {
    navigate("/register");
  };

  return (
    <div className="login-block">
      <div className="login-container">
        {/* <h1 className="login-app-title">
            Sky Scheduler
            <i className="fas fa-plane  login-logo"></i>
            
        </h1> */}
        <div className=" login-app-title">
          <img src={logo} alt="logo" className="banner_logo_image" />
          {/* <img src={image} alt="logo_text" className="banner_logo_image" /> */}
        </div>
        <div className="login_form_block">
        <div className="login-title">Login to your Account</div>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-input-group">
            <TextField
              label="Email Address"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
              fullWidth
              // size="small"
            />
          </div>
          <div className="login-input-group">
            <TextField
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              required
              fullWidth
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={handleTogglePasswordVisibility}
                    type="button"
                  >
                    <i
                      className={`fas ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    ></i>
                  </Button>
                ),
              }}
              // size="small"
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
          {error && <p className="login-error">{error}</p>}
          {success && <p className="login-success">{success}</p>}
          <div className="login-message">
            Are you a new user? Please
            <button
              type="button"
              className="login-redirect-button"
              onClick={handleRegisterRedirect}
            >
              Register
            </button>
          </div>
        </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
