import React from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import Banner from "../components/Banner";
import "../styles/LandingPage.css";
import image5 from "../images/infrastructure.jpg";
import image6 from "../images/img2.jpg";
import div_image1 from "../images/img3.jpeg";
import image7 from "../images/partner1.png";
import image8 from "../images/partner2.png";
import image9 from "../images/partner3.png";
import image10 from "../images/partner5.png";
import image11 from "../images/partner4.png";
import image12 from "../images/partner6.png";



function LandingPage() {
  const navigate = useNavigate();
  return (
    <>
      <Banner />
      <div className="landing_container">
        <div className="landing_inner_container">
          <div className="landing_container_inner_div">
            <div className="landing_inner_text_container">
              <div className="landing_inner_container_title">
                Shaping the Future with R&D and Technological Advancements
              </div>
              <div className="landing_inner_container_text">
                We are dedicated to advancing research and development (R&D)
                initiatives, hardware and software development, and innovation
                to create novel products and platforms.{" "}
              </div>
            </div>
            <div className="landing_inner_image_container">
              <img
                src={div_image1}
                alt="logo"
                className="landing_container_image"
              />
            </div>
          </div>
        </div>
        <div className="landing_inner_container">
          <div className="landing_container_inner_div">
            <div className="landing_inner_text_container">
              <div className="landing_inner_container_title">
                Advancing Aerospace by Developing Key Infrastructure and
                Services
              </div>
              <div className="landing_inner_container_text">
                Our focus is on making substantial advancements in the aerospace
                domain. Additionally, we facilitate the leasing of land to
                develop infrastructure and allied services for creating air
                corridors and airports and hire specialized equipment to support
                these turn-key projects.
              </div>
            </div>
            <div className="landing_inner_image_container">
              <img
                src={image5}
                alt="logo"
                className="landing_container_image"
              />
            </div>
          </div>
        </div>
        <div className="landing_inner_container">
          <div className="landing_container_inner_div">
            <div className="landing_inner_text_container">
              <div className="landing_inner_container_title">
                Enhancing Aerospace Efficiency and Safety through Expertise and
                Innovation
              </div>
              <div className="landing_inner_container_text">
                Our multifaceted approach ensures that we cater to the diverse
                needs of our clients while adhering to the highest standards of
                safety and quality. With a commitment to collaboration and
                excellence, we aim to make a significant contribution to the
                aerospace sector and foster growth within the industry.
              </div>
            </div>
            <div className="landing_inner_image_container">
              <img
                src={image6}
                alt="logo"
                className="landing_container_image"
              />
            </div>
          </div>
        </div>
        <div className="landing_inner_container partners_container">
          <div className="landing_container_inner_div partners_inner_container">
            <div className="partners_title"> Our Partners</div>
            <div className="partners_profiles">
              <div
                className="partners_profiles_each"
                // onClick={() => {
                //   window.location.href = "https://www.reva.edu.in/";
                // }}
              >
                <img
                  src={image9}
                  className="partners_profile_images atom8_logo"
                  loading="lazy"
                  alt="atom8"
                />
              </div>

              <div
                className="partners_profiles_each"
                onClick={() => {
                  window.open("https://www.cingularity.in/","_blank");
                }}
              >
                <img
                  src={image8}
                  className="partners_profile_images"
                  loading="lazy"
                  alt="cingular"
                />
              </div>
              <div
                className="partners_profiles_each"
                onClick={() => {
                  window.open("https://curledge-d0967.web.app/index.html","_blank");

                }}
              >
                <img
                  src={image11}
                  className="partners_profile_images"
                  loading="lazy"
                  alt="Curl Tech"
                />
              </div>
              <div
                className="partners_profiles_each"
                onClick={() => {
                  // window.location.href = "https://flightmode.co.in/";
                  window.open("https://flightmode.co.in/","_blank");
                }}
              >
                <img
                  src={image10}
                  className="partners_profile_images"
                  loading="lazy"
                  alt="Flight Mode"
                />
              </div>
              <div
                className="partners_profiles_each"
                onClick={() => {
                  window.open("https://www.reva.edu.in/","_blank");

                }}
              >
                <img
                  src={image12}
                  className="partners_profile_images"
                  loading="lazy"
                  alt="Model Aerosports"
                />
              </div>
              <div
                className="partners_profiles_each"
                onClick={() => {
                  window.open("https://www.reva.edu.in/","_blank");

                }}
              >
                <img
                  src={image7}
                  className="partners_profile_images"
                  loading="lazy"
                  alt="Reva"
                />
              </div>
              
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default LandingPage;
