import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import format from "date-fns/format";

import { blockedDatesList } from "../apis/apiCalls";
import LoginPopup from "../components/LoginPopup";
import CustomRadioGroup from "../components/CustomPictureRadioButton";
import image1 from "../images/flyingarea1.jpg";
import image2 from "../images/flyingarea2.jpg";
import "../styles/BookingCalendar.css";

const BookingForm = ({
  handleBooking,
  calendarPosition,
  confirmButton,
  bookingLabel,
}) => {
  const radioOptions = [
    {
      value: "Corridor1",
      label: "Bangalore Aviation Corridor 1",
      location: "Harohalli",
      imageUrl: image1,
      availability_status: "Slots Available",
      iconClass: "fa-solid fa-check-circle", // Font Awesome icon class
      color: "green",
    },
    {
      value: "Corridor2",
      label: "Bangalore Aviation Corridor 2",
      location: "Kengeri",
      imageUrl: image2,
      availability_status: "Limited Slots Available",
      iconClass: "fa-solid fa-exclamation-circle", // Font Awesome icon class
      color: "orange",
    },
    // { value: 'third', label: 'Third', imageUrl: 'https://via.placeholder.com/20' },
  ];
  const [startDate, setStartDate] = useState(null);
  const [count, setcount] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [blockedDates, setBlockedDates] = useState([]);
  const [location, setLocation] = useState(radioOptions[0].value);
  const [error, setError] = useState("");
  const [range, setRange] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isMobile, setisMobile] = useState(false);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const nextSevenDays = new Date(today);
  nextSevenDays.setDate(today.getDate() + 7);

  const predefinedRanges = [
    {
      label: "Today",
      value: [today, today],
    },
    {
      label: "Tomorrow",
      value: [tomorrow, tomorrow],
    },
    {
      label: "Next 7 Days",
      value: [today, nextSevenDays],
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlockedDates = async () => {
      try {
        const result = await blockedDatesList(location);
        // await deleteBlockedDates();
        setBlockedDates(result);
      } catch (err) {
        if (err.response && err.response.data.msg === "Token has expired") {
          setShowLoginPopup(true); // Show the login popup
        } else {
          console.log(error);
        }
      }
    };
    fetchBlockedDates();
  }, [showLoginPopup, location]);

  useEffect(() => {
    setRange([startDate, endDate]);
  }, [startDate, endDate]);

  useEffect(() => {
    const bookingDates = JSON.parse(localStorage.getItem("bookingDates"));
    if (bookingDates) {
      const tempStartDate = parsedDate(bookingDates.startDate);
      const tempEndDate = parsedDate(bookingDates.endDate);
      setStartDate(new Date(tempStartDate));
      setEndDate(new Date(tempEndDate));
      setLocation(bookingDates.location);
    }

    const handleStorageChange = (event) => {
      if (event.key === "bookingDates") {
        const bookingDates = JSON.parse(event.newValue);
        const tempStartDate = parsedDate(bookingDates.startDate);
        const tempEndDate = parsedDate(bookingDates.endDate);
        setStartDate(new Date(tempStartDate));
        setEndDate(new Date(tempEndDate));
        setLocation(bookingDates.location);
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  // const isDayBlocked = day => blockedDates.includes(day.toISOString().split('T')[0]); //taking the next day because of conversion
  useEffect(() => {
    window.innerWidth <= 768 ? setisMobile(true) : setisMobile(false);
  }, [window.innerWidth]);

  const isDayBlocked = (day) => {
    const date = day.toLocaleDateString("en-CA"); // Format as "YYYY-MM-DD"
    return blockedDates.includes(date);
  };
  const weekendSelectorForCorridor2 = (date) => {
    const day = date.getDay();
    // Return true to disable the date if it's a weekday
    return day !== 0 && day !== 6; // 0 is Sunday, 6 is Saturday
  };

  const confirmBooking = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setShowLoginPopup(true);
    } else {
      handleBookingLocally();
    }
  };
  const handleRangeChange = (value) => {
    setRange(value);
    if (value) {
      setStartDate(value[0]);
      setEndDate(value[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const handleBookingLocally = async () => {
    localStorage.setItem(
      "bookingDates",
      JSON.stringify({ startDate, endDate, location })
    );
    handleBooking(startDate, endDate);
  };

  const parsedDate = (dateparam) => {
    let date = String(new Date(dateparam).toLocaleDateString());
    const [month, day, year] = date.split("/"); // split by "/"
    // const newDate = new Date(`${year}-${month}-${day}`);
    // return newDate;
    return `${year}-${day}-${month}`;
  };
  // console.log(location);
  // const { combine, allowedMaxDays, beforeToday } = DateRangePicker;

  const beforeToday = (day) => {
    const today = new Date();
    return day < today.setHours(0, 0, 0, 0); // Disable all past dates
  };

  const shouldDisableDate = (day) => {
    return (
      beforeToday(day) ||
      isDayBlocked(day) ||
      (location == "corridor2" && weekendSelectorForCorridor2(day))
    );
  };
  return (
    <>
      <div className="location_div">
        <div>Choose Your Preferred Location:</div>
        <div className="location_selection_buttons_div">
          <CustomRadioGroup
            radioOptions={radioOptions}
            setLocation={setLocation}
            location={location}
          />
        </div>
      </div>

      <div className="date-range-picker">
        {isMobile && (
          <div className="mobile_booking_dates_header">
            Select Booking Dates:
          </div>
        )}
        <DateRangePicker
          label={!isMobile && (bookingLabel ? bookingLabel : "Select Booking Dates:")}
          value={range}
          onChange={handleRangeChange}
          placement={calendarPosition ? calendarPosition : "bottomStart"} // Sets the calendar to appear on top
          appearance="default" // Set style appearance
          // placeholder="Select Date Range"
          size="lg"
          showOneCalendar={window.innerWidth <= 768 ? true : false}
          block
          showHeader={false}
          // weekStart={1}
          // editable={false}
          // ranges={[]}
          ranges={predefinedRanges}
          shouldDisableDate={shouldDisableDate}
          renderValue={([start, end]) => {
            return (
              format(start, "EEE, d MMM") +
              "   To   " +
              format(end, "EEE, d MMM")
            );
          }}
        />

        <button
          className="book-button"
          onClick={() => {
            confirmBooking();
          }}
          disabled={!startDate || !endDate}
        >
          {confirmButton ? confirmButton : "Confirm Booking"}
        </button>
      </div>
      <p className="booking-warning">
        <i className="fa-solid fa-circle-info"></i>
        Blocked dates cannot be booked. If you select a range that includes
        blocked dates, only available dates will be reserved.
      </p>
      {error && <p className="booking-error">{error}</p>}
      {showLoginPopup && (
        <LoginPopup onClose={() => setShowLoginPopup(false)} />
      )}
    </>
  );
};

export default BookingForm;
