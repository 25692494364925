import React, { useEffect, useState } from 'react';
import { RadioGroup, Radio } from '@mui/material';
import MyFormControlLabel from '@mui/material/FormControlLabel';

import "../styles/CustomRadioGroup.css"


function CustomRadioGroup({radioOptions,setLocation,location}) {
  // Example data array to iterate over
  const [selectedOption, setSelectedOption] = useState(location)
  // console.log(selectedOption,"selected")
  const handleSelectionChange = ( e) => {
    setSelectedOption(e.target.value)
    setLocation(e.target.value)
  }
  

  return (
    <RadioGroup name="use-radio-group" defaultValue="first" className='radio_button_group_div'
      value={location}
      onChange={(e) => {handleSelectionChange(e)}}
      
      >
      {radioOptions.map((option) => (
        <div key={option.value} className="option_container">
          <MyFormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />} // Handle the change event
            label={
              
              <div className="radio_button_container" >
                <img
                  src={option.imageUrl}
                  alt={option.label}
                  className='radio_button_location_image'
                />
                <div className='radio_button_description'>
                  <div>{option.label}</div>
                  <div className="flying_location_div">{option.location}</div>
                  <div className="availability_status">
                  <i className={option.iconClass} style={{ color: option.color, marginRight: '8px',margin:'auto' }}></i>
                  <span className='availability_status_desc'>{option.availability_status}</span>
                  </div>
                </div>
                
              </div>
            }
          />
        </div>
      ))}
    </RadioGroup>
  );
}

export default CustomRadioGroup;
