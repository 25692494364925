import React from 'react'
import "../styles/Footer.css"
import image2 from "../images/AeroQuest_logo_1.png";
import image from "../images/AeroQuest_2.png";

function Footer() {
    return (
        <>
        <div className="footer_main_div">
            {/* <div className="footer_title_division">
            <div className="footer_logo">
              <img src={image2} alt="logo" className="logo_image" />
              <img src={image} alt="logo_text" className="logo_image" />
            </div>
            </div> */}
            {/* <div className="logo_div">
                <img src={image2}  alt='logo' className='logo_image_footer'/>
                <img src = {image} alt = 'AeroQuest' className='title_image_footer'/>
            </div> */}
            {/* <div className="copyright_div"> */}
                ©2024 AeroQuest Innovations Pvt. Ltd. All Rights Reserved.
                {/* </div> */}
            
        </div>
        </>
    )
}

export default Footer
