import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Popup from "../components/PopupComponent";
import BookingForm from "./BookingCalendar";
import "../styles/HomePage.css";

const HomePage = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setisPopupOpen] = useState(true);

  const handleBooking = async (startDate, endDate) => {
    console.log(startDate, endDate, "in home page");
    navigate("/summary");
    // try {
    //   const result = await createBooking({"booking_start_date":startDate.toISOString().split('T')[0],"booking_end_date":endDate.toISOString().split('T')[0]});
    //   console.log(result);

    // } catch (err) {
    //   if(err?.response?.data?.message) setError(err.response.data.message);
    //   else setError("An error occurred during booking, please check and select available booking dates");
    //   console.log(err)
    // }
    // To Clear the error message after 5 seconds (5000ms)
    // setTimeout(() => {
    //   setError(null);
    // }, 5000);
  };

  return (
    <div className="booking-page">
      {/* <div className="side-bar-container">
        <Sidebar/>
      </div> */}
      <div className="booking-page-container">
        <div className="booking-page-inner-container">
          <div className="booking-page-inner-div">
            <BookingForm
              handleBooking={handleBooking}
              calendarPosition="topStart"
            />
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <Popup>
          <div className="summary-popup-header-content">
            <div className="summary-popup-header-content-title">
              <div className="reservation_successful_icon_container">
                <i className="fa-solid fa-circle-info reservation_successful_icon"></i>
              </div>
              <h3 className="text_with_icon">Information</h3>
            </div>
            <button
              className="summary-popup-close-btn"
              onClick={() => setisPopupOpen(false)}
            >
              &times;
            </button>
          </div>
          <div className="summary-popup-body">
            {/* <p><strong>Booking ID:</strong> {bookingId}</p> */}
            {/* <p><strong>Start Date:</strong> {bookingDetails.startDate}</p>
            <p><strong>End Date:</strong> {bookingDetails.endDate}</p> */}
            {/* <p>Awesome! Please check your mail for further details.</p> */}
            <h3>Thank You for Choosing Us!</h3>
            <p>
              This platform is currently in its trial phase and does not accept
              bookings at this time. It is available for demonstration purposes
              only.
            </p>
            {/* <i className="fa fa-envelope" aria-hidden="true"></i>   */}
          </div>
          <div className="summary-popup-footer">
            <button
              className="summary-popup-action-btn"
              onClick={() => setisPopupOpen(false)}
            >
              OK
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default HomePage;
