import React from 'react';
import { Route,Routes,useLocation } from 'react-router-dom';

import Login from './pages/LoginPage';
import Register from './pages/RegistrationPage';
import SummaryPage from './pages/SummaryPage';
import Navbar from './components/Navbar'

import './App.css';
import HomePage from './pages/HomePage';
import test from './pages/Test';
import LandingPage from './pages/LandingPage';
import Footer from './components/Footer';



function App() {
  const location = useLocation();
  const noNavbarRoutes = ["/login", "/register","/","/sample"];
  return (
    <>
      {!noNavbarRoutes.includes(location.pathname) && < Navbar/>}
      <Routes>
        <Route path='/' Component={LandingPage}/>
        <Route path='/login' Component={Login}/>
        <Route path='/register' Component={Register}/>
        <Route path='/home' Component={HomePage}/>
        <Route path='/summary' Component={SummaryPage}/>
        <Route path='/sample' Component={test}/>

      </Routes>
      {!noNavbarRoutes.includes(location.pathname) && < Footer/>}

    </>
  );
}

export default App;
