


import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';

import { loginUser } from "../apis/apiCalls";
import "../styles/LoginPage.css"
import "../styles/LoginPopup.css"

function LoginPopup({ onClose }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }; 
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
    
        try {
          const result = await loginUser(email, password);
          onClose();
    
        } catch (err) {
          if(err.response.data.message) setError(err.response.data.message);
          else setError("An error occurred during Login");
          console.log(err)
        }
      };
      const handleRegisterRedirect = () => {
        navigate('/register')
      }
    return (
        <div className='login-popup-overlay'>
            <div className='login-popup'>
                <p className="login-error">Your login has been timed-out</p>
                <div className="login-title">Please Login to your Account</div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-input-group">

                    <TextField
                        label="Email Address"
                        variant="outlined" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required
                        fullWidth
                        size="small"
                    />
                    
                    </div>
                    <div className="login-input-group">
                    <TextField
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    required
                    fullWidth
                    InputProps={{
                        endAdornment: (
                        <Button onClick={handleTogglePasswordVisibility} type="button">
                            <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                        </Button>
                        ),
                    }}
                    size="small"
                    />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                    {error && <p className="login-error">{error}</p>}
                    <div className="login-message">
                        Are you a new user? Please                  
                        <button
                        type="button"
                        className="login-redirect-button"
                        onClick={handleRegisterRedirect}
                        >
                        Register
                        </button>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPopup
