import axios from 'axios';

//creation of reusable axios instance for API calls
export const apiClient = axios.create({
    baseURL:'http://127.0.0.1:5000/api',
    headers : {
        'Content-Type':'application/json',
    },
});

//adding a request interceptor to include token in API calls
// runs before every request and add token to every req
apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if(token){
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
},(error) => {
    return Promise.reject(error);
})