import React, { useEffect, useState } from "react";
import "../styles/SummaryPage.css";
// import Sidebar from "../components/Sidebar";
// import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BookingForm from "./BookingCalendar";
import { createBooking } from "../apis/apiCalls";
import Popup from "../components/PopupComponent";
import format from "date-fns/format";

function SummaryPage() {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [userName, setuserName] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const price_per_day = process.env.REACT_APP_BOOKING_PER_DAY_PRICE;
  const GST_value = process.env.REACT_APP_GST_VALUE;
  const booking_fee = process.env.REACT_APP_BOOKING_FEE_VALUE;
  const reservation_date = new Date().toISOString().split('T')[0]
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(0)
  const navigate = useNavigate()
  const parsedDate = (dateparam) => {
    let date = String(new Date(dateparam).toLocaleDateString());
    const [month, day, year] = date.split("/"); // split by "/"
    // const newDate = new Date(`${year}-${month}-${day}`);
    return `${year}-${day}-${month}`;
  }
  useEffect(() => {
    // Initial load from local storage
    const loadBookingDetails = () => {
        const bookingDates = JSON.parse(localStorage.getItem("bookingDates"));
        setuserName(localStorage.getItem("username"));
        if (bookingDates) {
            const tempStartDate = parsedDate(bookingDates.startDate);
            const tempEndDate = parsedDate(bookingDates.endDate);
            const totalDays = CalculateDays(tempStartDate, tempEndDate);
            const location = bookingDates.location;
            setBookingDetails({
                startDate: tempStartDate,
                endDate: tempEndDate,
                totalDays: totalDays,
                subtotal: totalDays * price_per_day,
                totalCost: totalDays * price_per_day + Number(GST_value) + Number(booking_fee),
                station : location,
            });
        }
    };
    
    loadBookingDetails();

    // Listen for local storage changes across tabs
    const handleStorageChange = (event) => {
        if (event.key === "bookingDates" || event.key === "username") {
            loadBookingDetails();
        }
    };
    
    window.addEventListener("storage", handleStorageChange);
    
    console.log(bookingDetails,"came ")
    
    // Cleanup listener on unmount
    return () => {
        window.removeEventListener("storage", handleStorageChange);
    };
}, [localStorage.getItem("bookingDates"), localStorage.getItem("username"),trigger]);


  const handleBookNow = () => {
    navigate('/home')
  }
  const cancelBooking = () => {
    localStorage.removeItem('bookingDates');
    setBookingDetails(null)
  }
  const closePopup = () => {
    setIsPopupOpen(false)
    navigate('/home')
  }
  const confirmBooking = async() => {
    setLoading(true);
    try {
      console.log(bookingDetails)

      const result = await createBooking({"booking_start_date":bookingDetails.startDate,"booking_end_date":bookingDetails.endDate,"booking_station":bookingDetails.station});
      console.log(result);
      localStorage.removeItem('bookingDates');
      


    } catch (err) {
      // if(err?.response?.data?.message) setError(err.response.data.message);
      // else setError("An error occurred during booking, please check and select available booking dates");
      console.log(err)
    }
    finally{
      
      setIsPopupOpen(true);
      setLoading(false);
    }
    // To Clear the error message after 5 seconds (5000ms)
    // setTimeout(() => {
    //   setError(null);
    // }, 5000);
  }
  const CalculateDays = (start, end) => {
    // Calculate the difference in time (milliseconds)
    const timeDifference = new Date(end).getTime() - new Date(start).getTime();

    // Convert time difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24) + 1;
    return daysDifference;
  };
  const handleBooking = async(startDate, endDate) => {
    console.log(startDate, endDate,"in home page");
    setTrigger(trigger+1);

  }
  const displayFormattedDate  = (date) => {
    return format(date, "do MMM, yyyy")
  }
  return (
    <div className="summary-page">
      {/* <div className="summary-left-div">
        <Sidebar />
      </div> */}
      <div className="summary-middle-div">
        {bookingDetails ? (
          <>
            <div>
              
              <div className="booking_details_container">
                <div className="reservation_successful_container">
                  <div className="reservation_successful_icon_container">
                    <i className="fa-solid fa-circle-check reservation_successful_icon"></i>
                  </div>
                  <div className="reservation_successful_message_container">
                    <div className="reservation_successful_message_div">
                      Dates selected! Finalize your booking with one last step
                    </div>
                    <div className="reservation_payment_required_div">
                      {/* Payment is required to finalize the booking! */}
                      Once confirmed, you'll receive a confirmation email with all the details.
                    </div>
                  </div>
                </div>
                <div className="booking_details_outer_div">
                 
                    <div className="booking_details_title">
                      <button className="go_back_button" onClick={() => {navigate('/home')}}><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back</button>
                      
                      <div>
                        Booking details
                      </div>
                    </div>
                    <BookingForm handleBooking={handleBooking} confirmButton="Apply Changes" bookingLabel="Select to Modify Booking Dates"/>
                    <div className="booking_details_inner_div">
                        <div className="booking_details_inner_most_div">
                            <div className="booking_details_inner_most_each_div">
                                <div className="booking_details_inner_most_each_div_title">Name</div>
                                <div className="booking_details_inner_most_each_div_data">{userName}</div>
                            </div>
                            <div className="booking_details_inner_most_each_div">
                                <div className="booking_details_inner_most_each_div_title">Start Date</div>
                                <div className="booking_details_inner_most_each_div_data">{displayFormattedDate(bookingDetails.startDate)}</div>
                            </div>
                            
                        </div>
                        <div className="booking_details_inner_most_div">
                          <div className="booking_details_inner_most_each_div">
                                <div className="booking_details_inner_most_each_div_title">Date of Reservation</div>
                                <div className="booking_details_inner_most_each_div_data">{displayFormattedDate(reservation_date)}</div>
                            </div>
                        
                            <div className="booking_details_inner_most_each_div">
                                <div className="booking_details_inner_most_each_div_title">End Date</div>
                                <div className="booking_details_inner_most_each_div_data">{displayFormattedDate(bookingDetails.endDate)}</div>
                            </div>
                        </div>
                        <div className="booking_details_inner_most_div">
                            <div className="booking_details_inner_most_each_div">
                                <div className="booking_details_inner_most_each_div_title">Location/Venue</div>
                                <div className="booking_details_inner_most_each_div_data">Bangalore Aviation {bookingDetails.station}</div>
                            </div>
                            <div className="booking_details_inner_most_each_div">
                                <div className="booking_details_inner_most_each_div_title">Total Days Reserved</div>
                                <div className="booking_details_inner_most_each_div_data">{bookingDetails.totalDays}</div>
                            </div>
                        </div>

                    </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="empty_cart_container">
              <div className="empty_cart_image">
                <img src="/assets/images/empty_cart_image.png" alt="Cart is empty"/>
              </div>
              <div className="empty_cart_message">
                No bookings in progress!
              </div>
              <div className="empty_cart_book_now_button_container">
                <button className="empty_cart_book_now_button" onClick={handleBookNow}>Book Now</button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="summary-right-div">
        
        {bookingDetails ? (
          <div className="summary-right-div-container">
          <div className="payment-title">Payment Summary</div>
          <div className="payment-details">
            <div className="payment-details-item">
              <div className="payment-details-item-title">Base Price</div>
              <div className="payment-details-item-data">₹{price_per_day}</div>
            </div>
            <div className="payment-details-item">
              <div className="payment-details-item-title">
                Total days
              </div>
              <div className="payment-details-item-data">
                {bookingDetails.totalDays}
              </div>
            </div>
            <div className="payment-details-item payment-details-item-total">
              <div className="payment-details-item-title">
                Subtotal (w.out taxes)
              </div>
              <div className="payment-details-item-data">
                ₹{bookingDetails.subtotal}
              </div>
            </div>
            <div className="payment-details-item">
              <div className="payment-details-item-title">GST</div>
              <div className="payment-details-item-data">₹{GST_value}</div>
            </div>
            <div className="payment-details-item">
              <div className="payment-details-item-title">Convenience Fee</div>
              <div className="payment-details-item-data">₹{booking_fee}</div>
            </div>
            <div className="payment-details-item payment-details-item-total">
              <div className="payment-details-item-title">
                Total (Incl. of all taxes)
              </div>
              <div className="payment-details-item-data">
                ₹{bookingDetails.totalCost}
              </div>
            </div>
          </div>
          <button className="summary-pay-now-button" onClick={confirmBooking} disabled={loading}>
            {loading ? 
            <>
            <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>  Booking in progress... </>
            : 'Confirm Booking'}
          </button>
          {/* <button className="summary-request-cancellation-button" onClick={cancelBooking}>
            Request Cancellation
          </button> */}
          </div>
        )
      :
      (
        <>
          Your previous booking details
        </>
      )}
        
      </div>
      {isPopupOpen && (
        <Popup>
          <div className="summary-popup-header-content">
            <div className="summary-popup-header-content-title">
              <div className="reservation_successful_icon_container">
                <i className="fa-solid fa-circle-check reservation_successful_icon"></i>
              </div>
              <h3>Booking Confirmed</h3>
            </div>
            <button className="summary-popup-close-btn" onClick={closePopup}>&times;</button>
          </div>
          <div className="summary-popup-body">
            {/* <p><strong>Booking ID:</strong> {bookingId}</p> */}
            {/* <p><strong>Start Date:</strong> {bookingDetails.startDate}</p>
            <p><strong>End Date:</strong> {bookingDetails.endDate}</p> */}
            {/* <p>Awesome! Please check your mail for further details.</p> */}
            <h3>Thank You for Choosing Us!</h3>
            <p>
           Please check your 
           email for booking details and further steps.</p>
           {/* <i className="fa fa-envelope" aria-hidden="true"></i>   */}
          </div>
          <div className="summary-popup-footer">
            <button className="summary-popup-action-btn" onClick={closePopup}>OK</button>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default SummaryPage;
