import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/Banner.css";
import web_image from "../images/banner_image.png";
import mobile_image from "../images/Flying_mobile.png";
import logo from "../images/Logo_4.png";

// import image2 from "../images/AeroQuest_logo_1.png";
// import image from "../images/AeroQuest_2.png";

function Banner() {
  const navigate = useNavigate();
  const navigateUser = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate('/login')
    } else {
      navigate('/home')

    }
  };
  return (
    <>
      <div className="banner_main_container">
        <div className="banner_inner_container">
          <div className="banner_text_container">
            
            {/* <img src={logo} className="banner_logo_image" /> */}
            <div className="banner_description_container">
            <div className="banner_logo_container">
              <img src={logo} alt="logo" className="banner_logo_image" />
              {/* <img src={image} alt="logo_text" className="banner_logo_image" /> */}
            </div>
              <div className="banner_inner_container_title">
                Empowering the Future of Aerospace
              </div>
              <div className="banner_inner_container_text">
                AeroQuest is committed to developing a comprehensive ecosystem
                that enables various aerospace activities, including
                manufacturing, testing, assembly, import and export, and
                providing tailored services to the aerospace industry.
              </div>
              <button
                className="banner_inner_container_book_button"
                onClick={() => {
                  navigateUser();
                }}
              >
                <i className="fa fa-calendar booking_calendar_icon" aria-hidden="true"></i> 
                Book Now!
              </button>
            </div>
          </div>
          <div className="banner_image_container">
            <div className="banner_image_inner_container">
              <img src={web_image} alt="logo" className=" banner_image" loading="lazy"/>
              <img src={mobile_image} alt="logo" className="  mobile_banner_image" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
